export const earningCochingData = [
    {
      courtName: "Wing Sports Academy",
      img1: "assets/img/booking/booking-05.jpg",
      img2: "assets/img/profiles/avatar-01.jpg",
      courtNo: "Court 1",
      bookingDate: "Booked on : 26 May 2023",
      playerName: "Johnson",
      dateTime1: "Mon, Jul 11",
      dateTime2: "06:00 PM - 08:00 PM",
      payment: "$120",
      additionalGuests: "2",
      paidOn: "Mon, Jul 12",
      download: "Download",
      lessontype:"Single Lesson"
    },
    {
      courtName: "Feather Badminton",
      img1: "assets/img/booking/booking-04.jpg",
      img2: "assets/img/profiles/avatar-02.jpg",
      courtNo: "Court 2",
      bookingDate: "Booked on : 26 May 2023",
      playerName: "Andy",
      dateTime1: "Mon, Jul 11",
      dateTime2: "06:00 PM - 08:00 PM",
      payment: "$130",
      additionalGuests: "3",
      paidOn: "Mon, Jul 12",
      download: "Download",
      lessontype:"One Time"

    },
    {
      courtName: "Bwing Sports ",
      img1: "assets/img/booking/booking-03.jpg",
      img2: "assets/img/profiles/avatar-03.jpg",
      courtNo: "Court 3",
      bookingDate: "Academy Booked on : 26 May 2023",
      playerName: "Pranika",
      dateTime1: "Mon, Jul 11",
      dateTime2: "06:00 PM - 08:00 PM",
      payment: "$100",
      additionalGuests: "1",
      paidOn: "Mon, Jul 12",
      download: "Download",
      lessontype:"Single Lesson"

    },
  ];
  