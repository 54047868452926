import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import axios from "axios";
import { Alert } from "antd";
import { API_URL } from "../../ApiUrl";
import Swal from "sweetalert2";
import { MdReportGmailerrorred } from "react-icons/md";
import { BiMessageError } from "react-icons/bi";

// import "./register.css";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";

const Signin = () => {
  const route = all_routes;
  const navigate = useNavigate();
  const [input, setInput] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    role: "User",
  });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [registeredMobiles, setRegisteredMobiles] = useState<string[]>([]);
  const [policy, setpolicy] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const errorCheckbox = document.querySelectorAll(".err-checkbox");
  const errorFirstName = document.querySelectorAll(".err-firstName");
  const errorLastName = document.querySelectorAll(".err-lastName");
  const errorMobileNumber = document.querySelectorAll(".err-mobile");

  useEffect(() => {
    {
      const loginToken = localStorage.getItem("token");
      if (loginToken) {
        navigate("/user/user-dashborad");
      }
    }
  }, []);

  // console.log("this is the email", input)

  const showLoadingAlert = () => {
    Swal.fire({
      title: "Loading",
      html: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setTimeout(() => {
      Swal.close(); // Close the loading dialog after some time (simulation)
    }, 1000);
  };

  // const validationSchema = Yup.object({
  //   first_name: Yup.string().required("First name is required"),
  //   last_name: Yup.string().required("Last name is required"),
  //   mobile: Yup.string()
  //     .matches(/^\d{10}$/, "Mobile number must be 10 digits")
  //     .required("Mobile number is required"),
  //   role: Yup.string().required("Role is required"),
  // });

  // console.log(input.email, "<,,,,,,,,,,,,,,,input");
  const [error, setError] = useState("");

  const handleRole = (name: string, value: string) => {
    // console.log("role.....", name);
    if (name && value) {
      setInput((prevInput) => ({
        ...prevInput,
        [name]: value,
      }));
    }
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
      setInput((input) => ({
        ...input,
        [name]: value,
      }));

      setIsChecked(e.target.checked);

    if (name === "first_name" || name === "last_name") {
      if (/^[a-zA-Z]+$/.test(value) || value === "") {
        setInput((input) => ({
          ...input,
          [name]: value,
        }));
      } else {
        setError(`${name} must contain only letters`);
        alert("contain only letters")
      }
    } else if (name === "mobile") {
      // Validation for mobile number (10 digits only)
      if (/^\d{0,10}$/.test(value) || value === "") {
        setInput((input) => ({
          ...input,
          [name]: value,
        }));
      } else {
        setError("Mobile number must be 10 digits");
        alert("Mobile number must be 10 digits");
      }

    } 
    else if (name === "email") {
      // Validation for email format
      if (/^\S+@\S+\.\S+$/.test(value) || value === "") {
        setInput((input) => ({
          ...input,
          [name]: value,
        }));
      } else {
        setError("Please enter a valid email address");
        alert("Please enter a valid email address");
      }
    }
  };

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const handleSignUp = async (e: any) => {
    e.preventDefault();
    try {
      if (!input.first_name || !input.role) {
        setError("Please fill in all required fields.");
        // alert('Please fill first name field');
        errorFirstName.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.display = "block";
          }
        });
        return;
      } else {
        errorFirstName.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.display = "none";
          }
        });
      }
      if (!input.last_name) {
        setError("Please fill in all required fields.");
        // alert('Please fill last name field');
        errorLastName.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.display = "block";
          }
        });
        return;
      } else {
        errorLastName.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.display = "none";
          }
        });
      }
      if (!input.mobile) {
        setError("Please fill in all required fields.");
        // alert('Please fill mobile no. field');
        errorMobileNumber.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.display = "block";
          }
        });
        return;
      } else {
        errorMobileNumber.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.display = "none";
          }
        });
      }

      if (registeredMobiles.includes(input.mobile)) {
        setError(
          "Mobile number already registered. Please use a different number."
        );
        return;
      }
      // Check if all required fields are filled
      // const policyCheckbox = document.getElementById("policy") as HTMLInputElement;
      if (!isChecked) {
        // alert('Please agree to the terms and conditions');
        errorCheckbox.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.display = "block";
          }
        });
      } else {
        errorCheckbox.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.display = "none";
          }
        });

        // Proceed with registration
        // Simulate registration by adding the mobile number to the list of registered numbers
        setRegisteredMobiles((prevMobiles) => [...prevMobiles, input.mobile]);

        const response = await axios.post(`${API_URL}/user/signup`, input);
        if (response.data.success) {
          console.log("Sign up successful:", response);
          localStorage.setItem("token2", response.data.token);
          showLoadingAlert();
          navigate("/auth/verifyotp");
        } else {
          setError("Error in signing up: " + response.data.message);
          console.log(response)
        }
      }
    } catch (error) {
      setError("Error signing up: " + "error");
      console.log(error?.response?.data?.message)
    }
  };

  return (
    <div>
      <>
        {/* Main Wrapper */}
        <div className="main-wrapper authendication-pages">
          {/* Page Content */}
          <div className="content">
            <div className="container wrapper no-padding">
              <div className="row no-margin vph-100">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 no-padding">
                  <div className="banner-bg register">
                    <div className="row no-margin h-100">
                      <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                        <div className="h-100 d-flex justify-content-center align-items-center">
                          <div className="text-bg register text-center">
                            <button
                              type="button"
                              className="btn btn-limegreen text-capitalize"
                            >
                              <i className="fa-solid fa-thumbs-up me-3" />
                              register Now
                            </button>
                            <p>
                              Register now for our innovative sports software
                              solutions, designed to tackle challenges in
                              everyday sports activities and events.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 no-padding">
                  <div className="dull-pg">
                    <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                      <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                        <header className="text-center">
                          <Link to={route.home}>
                            <ImageWithBasePath
                              src="/assets/KHELO-INDORE-LOGO.png"
                              className="img-fluid img-logo"
                              alt="Logo"
                            />
                          </Link>
                        </header>
                        <div className="shadow-card">
                          <h2>Get Started With Khelo Indore</h2>
                          <p>
                            Ignite your sports journey with KheloIndore and get
                            started now.
                          </p>

                          <ul
                            className="nav nav-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link  d-flex align-items-center ${input.role === "User" ? "active" : ""}`}
                                id="user-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#user"
                                type="button"
                                role="tab"
                                aria-controls="user"
                                // aria-selected={input.role === "User"}
                                onClick={() => handleRole("role", "User")}
                              >
                                I am a User
                              </button>
                            </li>

                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link  d-flex align-items-center ${input.role === "Coach" ? "active" : ""}`}
                                id="coach-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#coach"
                                type="button"
                                role="tab"
                                aria-controls="coach"
                                // aria-selected={input.role === "Coach"}
                                onClick={() => handleRole("role", "Coach")}
                              >
                                I am a Coach
                              </button>
                            </li>

                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link  d-flex align-items-center ${input.role === "Venue Admin" ? "active" : ""}`}
                                id="venue-admin-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#venue-admin"
                                type="button"
                                role="tab"
                                aria-controls="venue-admin"
                                aria-selected={input.role === "Venue Admin"}
                                onClick={() =>
                                  handleRole("role", "Venue Admin")
                                }
                              >
                                Venue Admin
                              </button>
                            </li>
                          </ul>

                          <div className="tab-content" id="myTabContent">
                            <div
                              className="tab-pane fade show active"
                              // id="user"
                              role="tabpanel"
                              aria-labelledby="user-tab"
                            >
                              {/* Register Form */}
                              <form 
                              action="none"
                              onSubmit={handleSignUp}
                              >
                                <div className="form-group">
                                  <label htmlFor="first_name">
                                    First Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="group-img">
                                    <i className="feather-user" />
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="first_name"
                                      placeholder="First Name"
                                      name="first_name"
                                      pattern="[a-zA-Z]*"
                                      onInput={(e:any) => {
                                        // Prevent non-letter characters by modifying the value directly
                                        e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '');
                                      }}
                                      value={input.first_name}
                                      onChange={handleInputChange}
                                    />
                                    <p
                                      id="err-firstName"
                                      className="err-firstName"
                                      style={{
                                        fontSize: "0.9rem",
                                        margin: "1rem 0.75rem",
                                        display: "none",
                                        color: "red",
                                      }}
                                    >
                                      <BiMessageError
                                        style={{
                                          color: "red",
                                          marginRight: "8px",
                                        }}
                                      />
                                      &nbsp;First name is required
                                    </p>
                                  </div>
                                </div>
                                {/* Last Name */}
                                <div className="form-group">
                                  <label htmlFor="last_name">
                                    Last Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="group-img">
                                    <i className="feather-user" />
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="last_name"
                                      placeholder="Last Name"
                                      name="last_name"
                                      pattern="[a-zA-Z]*"
                                      onInput={(e:any) => {
                                        // Prevent non-letter characters by modifying the value directly
                                        e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '');
                                      }}
                                      value={input.last_name}
                                      onChange={handleInputChange}
                                    />
                                    <p
                                      id="err-lastName"
                                      className="err-lastName"
                                      style={{
                                        fontSize: "0.9rem",
                                        margin: "1rem 0.75rem",
                                        display: "none",
                                        color: "red",
                                      }}
                                    >
                                      <BiMessageError
                                        style={{
                                          color: "red",
                                          marginRight: "8px",
                                        }}
                                      />
                                      &nbsp;Last name is required
                                    </p>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label htmlFor="email">Email{" "}</label>
                                  <div className="group-img">
                                    <i className="feather-mail" />
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="email"
                                      placeholder="Email"
                                      name="email"
                                      value={input.email}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>

                                {/* Mobile */}
                                <div className="form-group">
                                  <label htmlFor="mobile">
                                    Mobile{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="group-img">
                                    <i className="feather-phone" />
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="mobile"
                                      placeholder="Mobile"
                                      name="mobile"
                                      value={input.mobile}
                                      onChange={handleInputChange}
                                    />
                                    <p
                                      id="err-mobile"
                                      className="err-mobile"
                                      style={{
                                        fontSize: "0.9rem",
                                        margin: "1rem 0.75rem",
                                        display: "none",
                                        color: "red",
                                      }}
                                    >
                                      <BiMessageError
                                        style={{
                                          color: "red",
                                          marginRight: "8px",
                                        }}
                                      />
                                      &nbsp;Mobile no. is required
                                    </p>
                                  </div>
                                </div>

                                <div className="form-check d-flex justify-content-start align-items-center policy">
                                  <div className="d-inline-block">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue=""
                                      value={input.isChecked}
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                      id="policy"
                                    />
                                  </div>
                                  <label
                                    className="form-check-label"
                                    htmlFor="policy"
                                  >
                                    By continuing you indicate that you read and
                                    agreed to the{" "}
                                    <Link to={route.termsCondition}>
                                      Terms of Use
                                    </Link>
                                  </label>
                                </div>
                                {/* <div className="form-check d-flex justify-content-start align-items-center policy">
                                  <div className="d-inline-block">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue=""
                                      // checked={isChecked}
                                      onChange={handleCheckboxChange}
                                      id="policy"
                                    />
                                  </div>
                                  <label
                                    className="form-check-label"
                                    htmlFor="policy"
                                  >
                                    Having a smartphone{" "}
                                  </label>
                                </div> */}
                                <p
                                  id="err-ckeckbox"
                                  className="err-checkbox"
                                  style={{
                                    fontSize: "0.9rem",
                                    margin: "1rem 0.75rem",
                                    display: "none",
                                    color: "red",
                                  }}
                                >
                                  <BiMessageError
                                    style={{ color: "red", marginRight: "8px" }}
                                  />
                                  &nbsp;Please agree to the terms and conditions
                                </p>

                                <button
                                  // to={route.verifyotp}
                                  className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                                  type="submit"
                                  // onClick={handleSignUp}
                                >
                                  Create Account
                                  <i className="feather-arrow-right-circle ms-2" />
                                </button>

                                <div className="bottom-text text-center">
                                  <p>
                                    Have an account?{" "}
                                    <Link to={route.login}>Login!</Link>
                                  </p>
                                </div>
                              </form>
                              {/* /Register Form */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Content */}
        </div>
        {/* /Main Wrapper */}
      </>
    </div>
  );
};

export default Signin;
