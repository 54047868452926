export const allCourtData = [
  {
    courtName: "Leap Sports Academy",
    courtNo: "Court 1",
    location: "Victoria, TX",
    amount: "$150",
    maxGuest: "1",
    additionalGuests: "2",
    addedOn: "Mon, Jul 12",
    details: "View Details",
    status: "checkbox",
    img: "assets/img/booking/booking-04.jpg",
  },
  {
    courtName: "Wing Sports Academy",
    courtNo: "Court 2",
    location: "Grand Rapids, MI",
    amount: "$140",
    maxGuest: "1",
    additionalGuests: "2",
    addedOn: "Wed, Jul 14",
    details: "View Details",
    status: "checkbox",
    img: "assets/img/booking/booking-02.jpg",
  },
  {
    courtName: "Feather Badminton Court 3",
    courtNo: "Court 3",
    location: "South Bend, IN",
    amount: "$160",
    maxGuest: "1",
    additionalGuests: "2",
    addedOn: "Sat, Jul 15",
    details: "View Details",
    status: "checkbox",
    img: "assets/img/booking/booking-01.jpg",
  },
  {
    courtName: "Bwing Sports Academy",
    courtNo: "Court 4",
    location: "Portland, OR",
    amount: "$160",
    maxGuest: "1",
    additionalGuests: "2",
    addedOn: "Mon, Jul 12",
    details: "View Details",
    status: "checkbox",
    img: "assets/img/booking/booking-03.jpg",
  },
  {
    courtName: "Marsh Academy",
    courtNo: "Court 5",
    location: "Jackson, MS",
    amount: "$150",
    maxGuest: "1",
    additionalGuests: "2",
    addedOn: "Fri, Jul 12",
    details: "View Details",
    status: "checkbox",
    img: "assets/img/booking/booking-05.jpg",
  },
];
